<template>

  <b-modal id="organization_edit_modal"
           ref="organization_edit_modal"
           v-model="show_modal"
           bg-variant="light"
           header-bg-variant="info"
           header-text-variant="white"
           hide-footer
           lazy
           size="lg"
           title="Manage organization"
           @hidden="hide_modal">
    <b-container>

      <div v-if="show_status">
        <b-row class="my-1 mb-4">
          <b-col sm="3">
            <label class="font-weight-bold"
                   for="organization_status">Organization status:</label>
          </b-col>
          <b-col sm="9">
            <b-form-select id="organization_status"
                           v-model="organization.states.name"
                           :disabled="disabled"
                           :options="states"
                           placeholder="Status"
                           type="text"
                           @input="on_input"></b-form-select>
          </b-col>
        </b-row>

        <b-row v-if="organization.states.name === 'REJECTED'"
               class="my-1 mb-4">
          <b-col sm="3">
            <label class="font-weight-bold"
                   for="rejected_reason">Rejected reason:</label>
          </b-col>
          <b-col sm="9">
            <b-form-input id="rejected_reason"
                          v-model="organization.rejected_reason"
                          :disabled="disabled"
                          placeholder="Reason"
                          type="text"></b-form-input>
          </b-col>
        </b-row>

        <hr>
      </div>

      <b-row class="my-1 mt-4">
        <b-col sm="3">
          <label class="font-weight-bold"
                 for="organization_type">Organization type:</label>
        </b-col>
        <b-col sm="9">
          <b-form-select id="organization_type"
                         v-model="organization.type"
                         :disabled="disabled"
                         :options="types"
                         placeholder="Type"
                         type="text"></b-form-select>
        </b-col>
      </b-row>

      <b-row class="my-1 mt-4 organization__input--side-padding">
        <label class="font-weight-bold"
               for="organization_name">Name:</label>
        <b-form-input id="organization_name"
                      v-model="organization.name"
                      :disabled="disabled"
                      placeholder="Name"
                      type="text"></b-form-input>
      </b-row>

      <b-row class="my-1 mt-4">
        <b-col sm="3">
          <label class="font-weight-bold"
                 for="organization_acronym">Acronym:</label>
          <b-form-input id="organization_acronym"
                        v-model="organization.acronym"
                        :disabled="disabled"
                        placeholder="Acronym"
                        type="text"></b-form-input>
        </b-col>
        <b-col>
          <label class="font-weight-bold"
                 for="organization_address">Address:</label>
          <b-form-input id="organization_address"
                        v-model="organization.address"
                        :disabled="disabled"
                        placeholder="Address"
                        type="text"></b-form-input>
        </b-col>
      </b-row>

      <b-row class="my-1 mt-4">
        <b-col sm="3">
          <label class="font-weight-bold"
                 for="organization_zip">ZIP code:</label>
          <b-form-input id="organization_zip"
                        v-model="organization['zip-code']"
                        :disabled="disabled"
                        placeholder="Zip code"
                        type="text"></b-form-input>
        </b-col>
        <b-col sm="6">
          <label class="font-weight-bold"
                 for="organization_city">City:</label>
          <b-form-input id="organization_city"
                        v-model="organization.city"
                        :disabled="disabled"
                        placeholder="City"
                        type="text"></b-form-input>
        </b-col>
        <b-col sm="3">
          <label class="font-weight-bold"
                 for="organization_country">Country:</label>
          <b-form-select id="organization_country"
                         v-model="organization.country"
                         :disabled="disabled"
                         :options="countries"
                         placeholder="Country"></b-form-select>
        </b-col>
      </b-row>

      <hr>

      <b-button class="right btn-warning ma-2"
                @click="hide_modal">Cancel
      </b-button>
      <b-button class="right ma-2"
                variant="info"
                @click="init_action">Save
      </b-button>

    </b-container>
  </b-modal>

</template>
<script>
  import countries from 'country-list';

  export default {
    name: 'organization_edit_form',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      organization_prop: {
        type: Object,
        default() {
          return {};
        },
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      show_status: {
        type: Boolean,
        default: false,
      },
      action: {
        type: String,
        default: 'create',
      },
    },
    data() {
      return {
        show_modal: this.value,
        organization: this.organization_prop,
        countries: countries.getNames(),
        types: [
          { value: 'HOSPITAL', text: 'HOSPITAL' },
          { value: 'INDUSTRY', text: 'INDUSTRY' },
          { value: 'INTERNATIONAL_ORGANIZATION', text: 'INTERNATIONAL ORGANIZATION' },
          { value: 'NGO', text: 'NGO' },
          { value: 'PRIVATE', text: 'PRIVATE' },
          { value: 'RESEARCH_INSTITUTE', text: 'RESEARCH INSTITUTE' },
          { value: 'UNIVERSITY', text: 'UNIVERSITY' },
          { value: 'OTHER', text: 'OTHER' },
        ],
        states: [
          { value: 'DRAFT', text: 'DRAFT' },
          { value: 'ACCEPTED', text: 'ACCEPTED' },
          { value: 'REJECTED', text: 'REJECTED' },
        ],
      };
    },
    methods: {
      hide_modal() {
        this.$emit('input', false);
      },
      init_action() {
        if (this.action === 'edit') {
          this.edit_organization();
        } else {
          this.create_organization();
        }
      },
      edit_organization() {
        const organization_id = this.organization._id.$oid;
        this.axios.post(`/organizations/${organization_id}`, this.organization)
          .then((result) => {
            if (result) {
              this.$notify({ type: 'success', title: 'Organization updated' });
            }
          })
          .catch(() => {
            this.$notify({ type: 'error', title: 'Could not update' });
          });
      },
      create_organization() {
        this.axios.put('/organizations/new', this.organization)
          .then((result) => {
            if (result.data) {
              this.$notify({ type: 'success', title: 'Organization created' });
              this.$emit('created', result.data);
            }
          })
          .catch(() => {
            this.$notify({ type: 'error', title: 'Could not create' });
          });
      },
      on_input() {
        delete this.organization.rejected_reason;
      },
    },
    watch: {
      value() {
        this.show_modal = this.value;
      },
      organization_prop() {
        this.organization = this.organization_prop;
      },
    },
  };
</script>

<style scoped>
  .organization__input--side-padding {
    padding-left: 15px;
    padding-right: 15px;
  }

  .btn-warning {
    color: #ffffff;
    background-color: #f0ad4e;
    border-color: #eea236
  }

  .btn-warning:hover {
    background-color: #ea8d0a;
    border-color: #eea236
  }
</style>
