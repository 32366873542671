<template>
  <b-card bg-variant="light"
          header="Organizations"
          header-bg-variant="dark"
          header-text-variant="white">
    <b-tabs v-model="selected_card"
            card>

      <b-tab lazy
             title="Organizations list">

        <div class="right div--button ma-3">
          Filtered rows: {{ organizations_length }}
        </div>

        <b-button class="right ma-3"
                  variant="info"
                  @click="init_organization(0, false, 'create')">Add new organization
        </b-button>

        <b-table :current-page="current_page"
                 :fields="fields"
                 :items="organizations"
                 :per-page="per_page"
                 class="table-responsive"
                 hover
                 outlined
                 responsive
                 striped>

          <template v-slot:top-row="{fields}">
            <proposal_filter :id="id"
                             v-model="organizations"
                             :fields="fields"
                             :not_include_key="['actions', 'ordinalNumber']">
            </proposal_filter>
          </template>

          <template v-slot:cell(ordinalNumber)="row">
            <base-column-ordinal-number v-bind="{index: row.index, perPage: per_page, currentPage: current_page}"/>
          </template>

          <template v-slot:cell(actions)="row">
            <base-icon-button-edit title="Manage organization"
                                   @click="init_organization(row.index, true, 'edit')"/>
          </template>

        </b-table>

        <b_table_footer v-model="loading"
                        :currentPage="current_page"
                        :data_length="organizations_length"
                        :perPage="per_page"
                        @change_page="change_page">
        </b_table_footer>

        <organization_edit_form v-model="show_modal"
                                :action="action"
                                :organization_prop="organization"
                                :show_status="show_status"
                                @created="add_created"></organization_edit_form>
      </b-tab>

    </b-tabs>
  </b-card>
</template>

<script>
  import BaseIconButtonEdit from '@/components/baseComponents/baseIcons/BaseIconButtonEdit';
  import b_table_footer from '@/components/b_table_footer';
  import organization_edit_form from '@/components/operational/organizations/organization_edit_form';
  import proposal_filter from '@/components/proposal_filter';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';
  import { ordinalNumber } from '@/helpers/commonTableColumns';

  export default {
    name: 'organization_dashboard',
    components: {
      BaseColumnOrdinalNumber,
      BaseIconButtonEdit,
      proposal_filter,
      b_table_footer,
      organization_edit_form,
    },
    computed: {
      organizations_length() {
        return this.organizations.length;
      },
    },
    data() {
      return {
        id: 1,
        selected_card: 1,
        organizations: [],
        organization: {},
        current_page: 1,
        show_status: false,
        action: undefined,
        per_page: 20,
        loading: false,
        show_modal: false,
        fields: [
          { ...ordinalNumber, thStyle: { backgroundColor: '#efcb0e' } },
          {
            key: 'acronym',
            label: 'Acronym',
            sortable: true,
            thStyle: { backgroundColor: '#efcb0e' },
          },
          {
            key: 'name',
            label: 'Name',
            sortable: true,
            thStyle: { backgroundColor: '#EFCB0E' },
          },
          {
            key: 'city',
            label: 'City',
            sortable: true,
            thStyle: { backgroundColor: '#EFCB0E' },
          },
          {
            key: 'country',
            label: 'Country',
            sortable: true,
            thStyle: { backgroundColor: '#EFCB0E' },
          },
          {
            key: 'type',
            label: 'Type',
            sortable: true,
            thStyle: { backgroundColor: '#EFCB0E' },
          },
          {
            key: 'states.name',
            label: 'State',
            sortable: true,
            thStyle: { backgroundColor: '#EFCB0E' },
          },
          {
            key: 'actions',
            label: 'actions',
            thStyle: { backgroundColor: '#EFCB0E' },
          },
        ],
      };
    },
    methods: {
      load_data() {
        this.loading = true;
        this.axios.get('/organizations/all')
          .then((response) => {
            this.organizations = response.data;
            this.$emit('setup_proposal', this.organizations);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      change_page(val) {
        this.current_page = val;
      },
      calculate_row_index(index) {
        return index + (this.per_page * (this.current_page - 1));
      },
      init_organization(index, show_status, action) {
        if (action === 'edit') {
          const idx = this.calculate_row_index(index);
          this.organization = this.organizations[idx];
        } else {
          this.organization = {};
        }
        this.show_status = show_status;
        this.action = action;
        this.toggle_modal();
      },
      add_created(organization) {
        this.organizations.push(organization);
        this.$emit('setup_proposal', this.organizations);
      },
      toggle_modal() {
        this.show_modal = !this.show_modal;
      },
    },
    created() {
      this.load_data();
    },
  };
</script>

<style scoped>
</style>
